/**
 * External Imports
 * */
import { useState, useEffect, useRef } from 'react'
import { NeuButton } from '@neutron/react'
import { connect, useSelector } from 'react-redux'
import moment from 'moment'
import { Dropdown } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
/**
 * Internal Imports
 * */
import { MultiSelectDropdown } from '../MultiselectDropdown'

import {
  getAssignmentAudit,
  getSnapshot,
  getMissingAssignments,
} from '../../../redux/actions/reports'
/**
 * Styling Imports
 * */
import Flex from '../styleLibrary'
import Input from '../neutronComponents/input/input'
import { SlidingPaneDiv } from './modifyReportSlidingPane.styles'

const ModifyReport = ({
  units,
  positions,
  user34,
  site,
  dispatch,
  authToken,
  slidingPaneOpen,
  isVisible,
  hideSlidingPane,
  showHideReportClassName,
  path: propsPath,
}) => {
  const reportsSlidingPaneOpen = useSelector(
    (state) => state.ui.reportSlidingPane.open,
  )
  const modalSlide = reportsSlidingPaneOpen
    ? 'reportSlideRight inner-shadow outer-shadow'
    : 'reportSlideLeft'

  const path = window.location.pathname.replace('/reports/', '')
  const prevSiteRef = useRef(site)

  const unitOptions = [{ key: 'All', value: 'All', text: 'All' }]
  units.forEach((unit, index) => {
    unitOptions.push({ key: index, value: unit.id, text: unit.displayName })
  })

  const allPosition = [{ key: 'All', value: 'All', text: 'All' }]
  const positionOptions = allPosition.concat(positions)

  const typeOptions = [
    { key: 'All', value: 'All', text: 'All' },
    { key: 'Location', value: 'Location', text: 'Location' },
    { key: 'Patient', value: 'Patient', text: 'Patient' },
  ]
  const timeOptions = [
    { key: '4', value: '4', text: 'Last 4 Hours' },
    { key: '8', value: '8', text: 'Last 8 Hours' },
    { key: '12', value: '12', text: 'Last 12 Hours' },
    { key: '24', value: '24', text: 'Last 24 Hours' },
    { key: '7Days', value: '7Days', text: 'Last 7 Days' },
  ]

  const getLocalData = JSON.parse(
    localStorage.getItem(`${user34}-${path}-${site}`),
  )

  let localPositions
  if (getLocalData !== null && getLocalData.position.length > 0) {
    localPositions = getLocalData.position
  } else {
    localPositions = [{ key: 'All', value: 'All', text: 'All' }]
  }
  const localHour =
    getLocalData !== null && getLocalData.hour !== '' ? getLocalData.hour : '6'
  const localMinutes =
    getLocalData !== null && getLocalData.minute !== ''
      ? getLocalData.minute
      : '00'

  let localUnits
  if (getLocalData !== null && getLocalData.unit.length > 0) {
    localUnits = getLocalData.unit
  } else {
    localUnits = [{ key: 'All', value: 'All', text: 'All' }]
  }

  const [dynamicHeight, setDynamicHeight] = useState(window.innerHeight - 140)

  const [type, setType] = useState('All')
  const [unit, setUnit] = useState(localUnits)
  const [position, setPosition] = useState(localPositions)
  const [positionList, setPositionList] = useState(positionOptions)
  const [time, setTime] = useState('12')
  const [date, setDate] = useState(
    path === 'snapshot' ? moment().subtract(1, 'day').format('MM-DD-YYYY') : '',
  )
  const [keyword, setKeyword] = useState('')
  const [hour, setHour] = useState(localHour)
  const [minute, setMinute] = useState(localMinutes)

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      window.addEventListener('resize', () =>
        setDynamicHeight(window.innerHeight - 140),
      )
      setPositionList(positionList)
      if (position.length === 0) {
        return setPosition([{ key: 'All', value: 'All', text: 'All' }])
      }
      if (unit.length === 0) {
        return setUnit([{ key: 'All', value: 'All', text: 'All' }])
      }
    }
    return () => (isMounted = false)
  }, [positionList, positionOptions, position, unit])

  useEffect(() => {
    if (prevSiteRef.current !== site) {
      setType('All')
      setUnit([{ key: 'All', value: 'All', text: 'All' }])
      setPosition([{ key: 'All', value: 'All', text: 'All' }])
    }

    prevSiteRef.current = site
  }, [site])

  const handleRunReport = () => {
    const formatType = type === 'All' ? '' : type
    const formatUnit = unit.some((u) => u.value === 'All')
      ? []
      : unit.map((u) => u.value)
    const formatPosition = position.some((pos) => pos.text === 'All')
      ? []
      : position.map((pos) => pos.text)

    const startDate =
      time === '7Days' ? moment().subtract(7, 'day').format('YYYY-MM-DD') : null
    const endDate = time === '7Days' ? moment().format('YYYY-MM-DD') : null
    const startAndEndTime = time === '7Days' ? moment().format('hh:mm A') : '0'
    const startDT = new Date().toUTCString()
    const utcTime =
      time === '7Days' ? moment.utc(startDT).format('hh:mm A') : null

    const formatTimeIncrementInHours = time === '7Days' ? '0' : time
    switch (path) {
      case 'assignmentaudit':
        const auditPositions = position.some((pos) => pos.text === 'All')
          ? []
          : position.map((pos) => pos.value)

        dispatch(
          getAssignmentAudit({
            siteId: site,
            time: time === '7Days' ? '0' : time,
            type: formatType,
            unitId: formatUnit,
            position: auditPositions,
            authToken: authToken,
            utcStartDate: startDate,
            utcEndDate: endDate,
            utcStartTime: utcTime,
            utcEndTime: utcTime,
          }),
        )
        dispatch({ type: 'ui/SET_REPORT_KEYWORD', payload: keyword })

        dispatch({
          type: 'ui/SET_AUDIT_TYPE_AND_TIME',
          payload: {
            type: type,
            time: time,
            startDate: moment(startDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
            endDate: moment(endDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
            startTime: startAndEndTime,
            endTime: startAndEndTime,
          },
        })
        hideSlidingPane()

        break
      case 'snapshot':
        const formattedDate = moment(date).format('YYYY-MM-DD')
        const formattedTime = `${hour}:${minute}:00`
        const newFormattedDateAndTime = new Date(
          formattedDate + ' ' + formattedTime,
        ).toUTCString()

        const snapshotUtcTime = newFormattedDateAndTime.substr(17, 5)
        const dateFromUTC = newFormattedDateAndTime.substr(5, 11)
        const newUTCDate = new Date(dateFromUTC).toLocaleDateString('en-US')
        const dateArray = newUTCDate.split('/')
        const month =
          dateArray[0].length === 1 ? '0' + dateArray[0] : dateArray[0]
        const day =
          dateArray[1].length === 1 ? '0' + dateArray[1] : dateArray[1]
        const year = dateArray[2]
        const snapshotUtcDate = year + '-' + month + '-' + day
        dispatch({ type: 'ui/SET_REPORT_KEYWORD', payload: keyword })
        dispatch(
          getSnapshot({
            date: snapshotUtcDate,
            time: snapshotUtcTime,
            //position will always be [] for the call we will filter once we get data
            position: [],
            unit: formatUnit,
            authToken: authToken,
            siteId: site,
          }),
        )
        dispatch({
          type: 'ui/SET_SNAPSHOT_DATE_AND_TIME',
          payload: {
            date: moment(date).format('MM/DD/YYYY'),
            time: `${hour}:${minute}`,
            position: formatPosition,
          },
        })
        localStorage.setItem(
          `${user34}-${path}-${site}`,
          JSON.stringify({
            unit,
            position,
            hour,
            minute,
          }),
        )
        hideSlidingPane()

        break
      case 'missingassignments':
        const startDateTime =
          moment(startDate).format('MM/DD/YYYY') + ' ' + startAndEndTime
        const endDateTime =
          moment(endDate).format('MM/DD/YYYY') + ' ' + startAndEndTime
        const timeStamp = moment({}).format('MM/DD/YYYY, HH:mm')
        const assignmentCall = () => {
          dispatch({
            type: 'ui/SET_MISSING_ASSIGNMENT_DATA',
            payload: {
              timeIncrementInHours: formatTimeIncrementInHours,
              timeReportGenerated: timeStamp,
              startDateTime,
              endDateTime,
            },
          })
          localStorage.setItem(
            `${user34}-${path}-${site}`,
            JSON.stringify({
              unit,
              position,
            }),
          )
          dispatch(
            getMissingAssignments({
              timeIncrementInHours: formatTimeIncrementInHours,
              startDate,
              startTime: utcTime,
              endDate,
              endTime: utcTime,
              position: formatPosition,
              unit: formatUnit,
              authToken: authToken,
              siteId: site,
            }),
          )
          hideSlidingPane()
        }
        assignmentCall()
        break
      default:
        break
    }
  }

  const arrayOf7Dates = []
  for (const n in [1, 2, 3, 4, 5, 6, 7]) {
    arrayOf7Dates.push(moment().subtract(n, 'days').format('MM-DD-YYYY'))
  }

  return (
    <div className={showHideReportClassName}>
      <SlidingPaneDiv className={modalSlide} style={{ height: dynamicHeight }}>
        <Flex
          direction={'column'}
          className="inner-shadow"
          style={{
            height: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            justifyContent: 'space-between',
            width: '430px',
          }}
        >
          <Flex direction={'column'} style={{ padding: '10px 15px' }}>
            {path === 'assignmentaudit' ? (
              <Flex
                direction={'column'}
                style={{
                  marginBottom: '10px',
                }}
              >
                <label className="genericLabel">Type</label>
                <Flex style={{ width: '100%' }}>
                  <Dropdown
                    style={{ width: '390px', height: '42px' }}
                    className="genericDropdown reportSlidingPaneDropdown"
                    ui="true"
                    value={type}
                    floating
                    search
                    selection
                    options={typeOptions}
                    onChange={(e, d) => {
                      setType(d.value)
                    }}
                  />
                </Flex>
              </Flex>
            ) : (
              <Flex direction={'column'}></Flex>
            )}
            <label className="genericLabel">Unit</label>
            <MultiSelectDropdown
              selections={unit}
              options={unitOptions}
              updateDropdownList={setUnit}
              id="unit"
            />

            <label className="genericLabel">Position</label>
            <MultiSelectDropdown
              selections={position}
              options={positionOptions}
              updateDropdownList={setPosition}
              id="position"
              style={{ height: '42px' }}
            />
            {propsPath === 'missingassignments' ||
            propsPath === 'assignmentaudit' ? (
              <Flex direction={'column'}>
                <label className="genericLabel">Time</label>

                <Dropdown
                  style={{ height: '42px' }}
                  className="genericDropdown reportSlidingPaneDropdown"
                  ui="true"
                  defaultValue={'12'}
                  search
                  floating
                  selection
                  options={timeOptions}
                  onChange={(e, d) => {
                    setTime(d.value)
                  }}
                />
              </Flex>
            ) : (
              <Flex direction={'column'}></Flex>
            )}
            {propsPath === 'snapshot' ? (
              <Flex direction={'column'}>
                <label className="genericLabel mb-2">{'Date & Time'}</label>
                <Flex>
                  <DateInput
                    dateFormat="MM-DD-YYYY"
                    className="Date-Time-CSS"
                    enable={arrayOf7Dates}
                    value={date}
                    disablecalendar={'true'}
                    icon={false}
                    style={{
                      marginRight: 10,
                      width: '230px',
                      height: 40,
                    }}
                    onChange={(e, { value }) => {
                      setDate(value)
                    }}
                  />
                  {propsPath === 'snapshot' && (
                    <Flex
                      style={{
                        width: '130px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Input
                        type="hour"
                        style={{
                          fontSize: '14px',
                          width: '50%',
                          border: 'solid 1px #DFDFDF',
                          borderRadius: '3px',
                          width: '59px',
                          height: '42px',
                          padding: '5px',
                        }}
                        handleInput={(e) => {
                          setHour(e)
                        }}
                        value={hour}
                      />
                      <h4
                        style={{
                          marginRight: '5px',
                          marginBottom: '10px',
                        }}
                      >
                        :
                      </h4>
                      <Input
                        type="minute"
                        style={{
                          fontSize: '14px',
                          width: '59px',
                          height: '42px',
                          border: 'solid 1px #DFDFDF',
                          borderRadius: '3px',
                          padding: '5px',
                        }}
                        handleInput={(e) => {
                          setMinute(e)
                        }}
                        value={minute}
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            ) : (
              <Flex direction={'column'}></Flex>
            )}
            {propsPath === 'snapshot' || propsPath === 'assignmentaudit' ? (
              <Flex direction={'column'}>
                {' '}
                <label
                  style={{
                    paddingTop: '20px',
                    color: '#1F2532',
                    fontFamily: 'HCA-Mark, Arial, sans-serif',
                    fontSize: '14px',
                  }}
                >
                  {propsPath !== 'snapshot'
                    ? 'Keyword (Name, MRN, 3-4 ID)'
                    : 'Keyword (Name, MRN)'}
                </label>
                <div className="ui left icon input Patient-Search w-100 mt2">
                  <i className="search icon" />
                  <input
                    style={{ width: 330, padding: '5px', height: '42px' }}
                    placeholder="  "
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
              </Flex>
            ) : (
              <Flex direction={'column'}></Flex>
            )}
          </Flex>

          <Flex
            style={{
              borderTop: '2px solid #bcbcbc',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Flex
              style={{
                padding: '10px 15px',
                marginRight: '20px',
                marginLeft: '20px',
                justifyContent: 'space-around',
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <div>
                <NeuButton
                  color="primary-80"
                  fill="flat-white"
                  onClick={hideSlidingPane}
                >
                  Cancel
                </NeuButton>
              </div>
              <div>
                <NeuButton
                  color="primary-80"
                  fill="raised"
                  onClick={() => handleRunReport()}
                >
                  Run Report
                </NeuButton>
              </div>
            </Flex>
          </Flex>
        </Flex>
      </SlidingPaneDiv>
    </div>
  )
}

export default connect()(ModifyReport)
