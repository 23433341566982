/**
 * External Imports
 * */
import { useState, useEffect } from 'react'
/**
 * Internal Imports
 * */
import {
  updateLocation,
  updateLocationStatus,
  createLocation,
} from '../../../redux/actions/locations'
/**
 * Styling Imports
 * */
import Flex from '../styleLibrary'
import { FlatTabButton } from '../styleLibrary'
import { Button } from '../userSlidingPane/userSlidingPane.styles'
import { SlidingPaneDiv } from './locationsSlidingPane.styles'
import Input from '../neutronComponents/input/input'
import Radio from '../neutronComponents/radio/radio'
import Loading from '../neutronComponents/Spinner'
import { useSelector } from 'react-redux'

const LocationsSlidingPane = ({
  data,
  dispatch,
  sites,
  selectedRoom,
  beds,
  allLocations,
  selectedUnit,
  selectedSite,
  source,
  authToken,
  loggedInUser,
  singleLocationToEdit,
  hideSlidingPane,
  showHideClassName,
  bedLoading,
}) => {
  const slidingPaneOpen = useSelector((state) => state.ui.slidingPane.open)
  const modalSlide = slidingPaneOpen ? 'slideLeft' : 'slideRight'

  const [activeSelected, setActiveSelected] = useState(
    data && data.status === 'Active' ? true : false,
  )
  const [hiddenSelected, setHiddenSelected] = useState(
    data && data.status === 'Hidden' ? true : false,
  )
  const [newDisplayName, setNewDisplayName] = useState(data.displayName)
  const [fsID, setFSID] = useState('')
  const [bedExists, setBedExists] = useState([])
  const [roomExists, setRoomExists] = useState([])
  const [bedDisplayNameIncludesRoom, setBedDisplayNameIncludesRoom] =
    useState(false)
  const facilityCoids = new Set(sites.map((site) => site.facilityCoid))

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (data.dataType === 'Beds' && newDisplayName) {
        setBedDisplayNameIncludesRoom(
          newDisplayName.includes(selectedRoom.text + '-'),
        )
        setBedExists(
          beds.filter(
            (bed) =>
              bed.displayName.toLowerCase() ===
              selectedRoom.text.toLowerCase() +
                '-' +
                newDisplayName.toLowerCase(),
          ),
        )
      }
      if (data.dataType === 'Rooms' && newDisplayName) {
        // TODO: Check this
        const rooms = allLocations.filter(
          (loc) =>
            loc.locationType.displayName === 'Room' &&
            loc.unit.id === selectedUnit.value &&
            loc.siteId === selectedSite?.value,
        )
        setRoomExists(
          rooms.filter(
            (room) =>
              room.displayName.toLowerCase() === newDisplayName.toLowerCase(),
          ),
        )
      }
      if (newDisplayName === '') {
        setBedExists([])
        setRoomExists([])
      }
    }
    return () => (isMounted = false)
  }, [newDisplayName])

  const handleUpdateLocation = () => {
    const newStatus = activeSelected ? 'Active' : hiddenSelected ? 'Hidden' : ''
    const updateStatus = newStatus !== data.status && newStatus !== ''
    const statusBody = { status: newStatus }

    if (source === 'add-location') {
      if (data.dataType === 'Units') {
        const unit = allLocations.filter(
          (loc) =>
            loc.locationType.displayName === 'Unit' &&
            loc.status === 'Active' &&
            loc.siteId === selectedSite?.value,
        )[0]

        const newIntegrationProperties = Object.assign(
          {},
          unit.integrationProperties,
          { FacilitySchedulerId: fsID },
        )
        
        const newFhirResourceUrl =
          unit.fhirResourceUrl.split('.', 1) + `.${newDisplayName}`

        const data = Object.assign({}, unit, {
          displayName: newDisplayName,
          integrationProperties: newIntegrationProperties,
          fhirResourceUrl: newFhirResourceUrl,
          status: newStatus,
        })

        delete data.id
        delete data._etag
        delete data._friendlyName

        dispatch(
          createLocation(
            authToken,
            selectedSite?.value,
            data,
            facilityCoids,
            loggedInUser,
          ),
        )
      }
      if (data.dataType === 'Rooms') {
        // TODO: Check this
        const room = allLocations.filter(
          (loc) =>
            loc.locationType.displayName === 'Room' &&
            loc.status === 'Active' &&
            loc.siteId === selectedSite?.value,
        )[0]
        const fhirLetter = room.fhirResourceUrl.split('-', 2)[1].split('.', 1)
        const newFhirResourceUrl =
          room.fhirResourceUrl.split('-', 1) +
          `-${fhirLetter}.` +
          `${selectedUnit.text}` +
          `-${fhirLetter}` +
          `.${newDisplayName}`

        const data = Object.assign({}, room, {
          displayName: newDisplayName,
          fhirResourceUrl: newFhirResourceUrl,
          status: newStatus,
          parent: {
            displayName: selectedUnit.text,
            id: selectedUnit.value,
          },
          unit: {
            displayName: selectedUnit.text,
            id: selectedUnit.value,
          },
        })
        delete data.id
        delete data._etag
        delete data._friendlyName

        dispatch(
          createLocation(
            authToken,
            selectedSite?.value,
            data,
            facilityCoids,
            loggedInUser,
            selectedUnit.value,
          ),
        )
      }
      if (data.dataType === 'Beds') {
        const bed = allLocations.filter((loc) => {
          return (
            loc.locationType.displayName === 'Bed' && loc.status === 'Active'
          )
        })[0]

        const fhirLetter = bed.fhirResourceUrl.split('-', 2)[1].split('.', 1)
        const newFhirResourceUrl =
          bed.fhirResourceUrl.split('-', 1) +
          `-${fhirLetter}.` +
          `${selectedUnit.text}-` +
          `${fhirLetter}.` +
          `${selectedRoom.text}-` +
          `${newDisplayName}`

        let hillRomRaulandProperties
        if (bed.integrationProperties.HrcBed) {
          hillRomRaulandProperties = {
            HrcBed: `${newDisplayName}`,
            HrcRoom: `${fhirLetter}.${selectedRoom.text}`,
            HrcUnit: `${fhirLetter}.${selectedUnit.text}`,
            MhbLocationId:
              `${fhirLetter}.` +
              `${selectedUnit.text}^` +
              `${fhirLetter}.` +
              `${selectedRoom.text}^` +
              `${newDisplayName}`,
          }
        } else {
          hillRomRaulandProperties = {
            HrcBed: `${newDisplayName}`,
            HrcRoom: `${fhirLetter}.${selectedRoom.text}`,
            HrcUnit: `${fhirLetter}.${selectedUnit.text}`,
            MhbLocationId:
              `${fhirLetter}.` +
              `${selectedUnit.text}^` +
              `${fhirLetter}.` +
              `${selectedRoom.text}^` +
              `${newDisplayName}`,
          }
        }

        const integProperties = Object.assign(
          {},
          bed.integrationProperties,
          hillRomRaulandProperties,
        )

        const data = Object.assign({}, bed, {
          assignments: [],
          displayName: selectedRoom.text + '-' + newDisplayName,
          integrationProperties: integProperties,
          fhirResourceUrl: newFhirResourceUrl,
          status: newStatus,
          assignable: true,
          parent: {
            displayName: selectedRoom.text,
            id: selectedRoom.value,
          },
          unit: {
            displayName: selectedUnit.text,
            id: selectedUnit.value,
          },
        })
        delete data.id
        delete data._etag
        delete data._friendlyName

        dispatch(
          createLocation(
            authToken,
            selectedSite?.value,
            data,
            facilityCoids,
            loggedInUser,
          ),
        )
      }
    } else {
      const filteredData = singleLocationToEdit

      const data = {
        displayName:
          newDisplayName !== '' && newDisplayName !== filteredData?.displayName
            ? newDisplayName
            : filteredData.displayName,
        locationType: filteredData.locationType,
        parent: filteredData.parent,
        unit: filteredData.unit,
        assignable: filteredData.assignable,
        status: filteredData.status,
        fhirResourceUrl: filteredData.fhirResourceUrl,
        integrationProperties: filteredData.integrationProperties,
        id: filteredData.id,
        facilityCoid: filteredData.facilityCoid,
        siteId: filteredData.siteId,
      }
      const facilityMetaDataString = sites.map((site) => site.facilityCoid)
      if (
        newDisplayName !== '' &&
        newDisplayName !== singleLocationToEdit.displayName
      ) {
        dispatch(
          updateLocation(
            authToken,
            data.siteId,
            data.id,
            data,
            facilityMetaDataString,
            updateStatus,
            statusBody,
            loggedInUser,
          ),
        )
      } else {
        if (newStatus !== singleLocationToEdit.status) {
          const cascade = true
          const unitValue = selectedUnit
            ? selectedUnit.value
            : singleLocationToEdit.locationType.displayName === 'Unit'
            ? singleLocationToEdit.id
            : ''
          dispatch(
            updateLocationStatus(
              authToken,
              selectedSite?.value,
              cascade,
              data.id,
              statusBody,
              facilityMetaDataString,
              loggedInUser,
              unitValue,
            ),
          )
        }
      }
    }
    hideSlidingPane()
  }
  let modalTitle
  if (source === 'sites') modalTitle = 'Site'
  if (source === 'units') modalTitle = 'Unit'
  if (source === 'rooms') modalTitle = 'Room'
  if (source === 'beds') modalTitle = 'Bed'

  return (
    <div className={showHideClassName}>
      <div
        onClick={hideSlidingPane}
        style={{ height: '100%', width: '100%', display: 'inline-block' }}
      />
      <SlidingPaneDiv
        className={modalSlide}
        style={{ overflowY: 'scroll', display: 'inline-block' }}
      >
        {bedLoading ? (
          <Loading square="50px" />
        ) : (
          <div style={{ position: 'relative', height: '100%' }}>
            <Flex direction="column">
              <Flex
                direction="row"
                style={{
                  justifyContent: 'space-between',
                  padding: '20px',
                }}
              >
                <div
                  style={{
                    fontSize: '20px',
                    fontWeight: '900',
                    color: '#1F2532',
                    fontFamily: 'HCA-Mark, Arial, sans-serif ',
                  }}
                >
                  {source !== 'add-location'
                    ? `Modify ${modalTitle}`
                    : `Add ${data.dataType.slice(0, data.dataType.length - 1)}`}
                </div>
                <FlatTabButton onClick={hideSlidingPane}>
                  <i
                    className="neu-avatar__icon material-icons"
                    style={{
                      color: 'grey',
                      cursor: 'pointer',
                    }}
                  >
                    close
                  </i>
                </FlatTabButton>
              </Flex>
            </Flex>
            <Flex direction="column">
              {(source === 'sites' ||
                source === 'units' ||
                source === 'add-location') && (
                <>
                  <Flex
                    direction="column"
                    style={{ justifyContent: 'space-between', padding: '20px' }}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#1F2532',
                        fontFamily: 'HCA-Mark',
                        paddingBottom: '15px',
                      }}
                    >
                      {data.dataType === 'Beds' ? 'Bed Name' : 'Displayed Name'}
                    </div>
                    <Input
                      className=""
                      style={{
                        fontSize: '20px',
                        height: '40px',
                        width: '100%',
                        border: 'solid #d9d9d6 1px',
                        borderRadius: '5px',
                        padding: '0 15px',
                      }}
                      type="text"
                      handleInput={(e) => {
                        setNewDisplayName(e)
                      }}
                      value={newDisplayName}
                    />
                    {bedExists.length > 0 && (
                      <div style={{ color: 'red' }}>
                        A bed with this name under this room already exists with{' '}
                        {bedExists[0].status} status.
                      </div>
                    )}
                    {roomExists.length > 0 && (
                      <div style={{ color: 'red' }}>
                        A room with this name under this room already exists
                        with {roomExists[0].status} status.
                      </div>
                    )}
                    {bedDisplayNameIncludesRoom && (
                      <div style={{ color: 'red' }}>
                        Please type bed name only.
                      </div>
                    )}
                  </Flex>
                  {data.dataType === 'Beds' && newDisplayName && (
                    <Flex
                      direction="column"
                      style={{
                        justifyContent: 'space-between',
                        padding: '5px 20px 20px 20px',
                        fontWeight: 'bold',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '14px',
                          color: '#1F2532',
                          fontFamily: 'HCA-Mark',
                          paddingBottom: '15px',
                        }}
                      >
                        {selectedRoom.text + '-' + newDisplayName}
                      </div>
                    </Flex>
                  )}
                </>
              )}
              {source === 'add-location' && data.dataType === 'Units' && (
                <>
                  <Flex
                    direction="column"
                    style={{
                      justifyContent: 'space-between',
                      padding: '5px 20px',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#1F2532',
                        fontFamily: 'HCA-Mark',
                        paddingBottom: '15px',
                      }}
                    >
                      Facility Scheduler ID
                    </div>
                    <Input
                      style={{
                        fontSize: '20px',
                        height: '40px',
                        width: '100%',
                        border: 'solid #d9d9d6 1px',
                        borderRadius: '5px',
                        padding: '0 15px',
                      }}
                      type="text"
                      handleInput={(e) => {
                        setFSID(e)
                      }}
                      value={fsID}
                    />
                  </Flex>
                </>
              )}
              {source !== 'sites' &&
                source !== 'units' &&
                source !== 'add-location' && (
                  <Flex
                    direction="column"
                    style={{ justifyContent: 'space-between', padding: '20px' }}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#1F2532',
                        fontFamily: 'HCA-Mark',
                        paddingBottom: '15px',
                      }}
                    >
                      {source === 'rooms' ? 'Room' : 'Bed'} Name
                    </div>
                    <div
                      style={{
                        fontSize: '20px',
                        color: '#1F2532',
                        fontFamily: 'HCA-Mark-Bold',
                      }}
                    >
                      {data.displayName}
                    </div>
                  </Flex>
                )}
              {source !== 'sites' && (
                <Flex
                  direction="column"
                  style={{ justifyContent: 'space-between', padding: '20px' }}
                >
                  <Flex style={{ padding: '5px' }}>
                    <Radio
                      click={() => {
                        setActiveSelected(!activeSelected)
                        setHiddenSelected(false)
                      }}
                      isSelected={activeSelected}
                    />
                    <div
                      style={{
                        marginLeft: '10px',
                        fontSize: '14px',
                        color: '#1F2532',
                      }}
                    >
                      Active
                    </div>
                  </Flex>
                  <Flex style={{ padding: '5px' }}>
                    <Radio
                      click={() => {
                        setActiveSelected(false)
                        setHiddenSelected(!hiddenSelected)
                      }}
                      isSelected={hiddenSelected}
                    />
                    <div
                      style={{
                        marginLeft: '10px',
                        fontSize: '14px',
                        color: '#1F2532',
                      }}
                    >
                      Hidden
                    </div>
                  </Flex>
                </Flex>
              )}
              {source !== 'rooms' &&
                source !== 'beds' &&
                source !== 'add-location' && (
                  <Flex
                    direction="column"
                    style={{ justifyContent: 'space-between', padding: '20px' }}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#1F2532',
                        fontFamily: 'HCA-Mark',
                        paddingBottom: '15px',
                      }}
                    >
                      EMR Name
                    </div>
                    <div
                      style={{
                        fontSize: '20px',
                        color: '#1F2532',
                        fontFamily: 'HCA-Mark-Bold',
                      }}
                    >
                      {data.emr ? data.emr : data.displayName}
                    </div>
                  </Flex>
                )}
              {source === 'sites' && (
                <Flex
                  direction="column"
                  style={{ justifyContent: 'space-between', padding: '20px' }}
                >
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#1F2532',
                      fontFamily: 'HCA-Mark',
                      paddingBottom: '15px',
                    }}
                  >
                    COID
                  </div>
                  <div
                    style={{
                      fontSize: '20px',
                      color: '#1F2532',
                      fontFamily: 'HCA-Mark-Bold',
                    }}
                  >
                    {data.facilityCoid}
                  </div>
                </Flex>
              )}
            </Flex>

            <Flex direction="column"></Flex>

            <div style={{ position: 'relative' }}>
              <div
                style={{
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'fixed',
                    bottom: 0,
                    width: '408px',
                    borderTop: '2px solid #bbb',
                    backgroundColor: 'white',
                  }}
                >
                  <Flex style={{ margin: '20px 30px' }}>
                    <Button
                      save={false}
                      style={{ cursor: 'pointer', color: '#E05929' }}
                      onClick={hideSlidingPane}
                    >
                      Cancel
                    </Button>
                    <Button
                      save={true}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: '#00558C',
                        color: 'white',
                      }}
                      onClick={(e) =>
                        bedExists.length > 0 || roomExists.length > 0
                          ? null
                          : handleUpdateLocation()
                      }
                    >
                      Save
                    </Button>
                  </Flex>
                </div>
              </div>
            </div>
          </div>
        )}
      </SlidingPaneDiv>
    </div>
  )
}

export default LocationsSlidingPane
